































































import { Component, Vue } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownText from '@/components/VsDropdownText/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { SubscriberAreaModule } from '@/store/modules/subscriberArea'
import { AppModule } from '@/store/modules/app'
import { forgetContact } from '@/api/consoleApi/subscriberArea'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { pSBC } from '@/utils/colors'

@Component({
    name: 'SubscriberAreaTop',
    components: {
        VsSectionHeader,
        VsDropdownText,
        VsSeparator,
        VsConfirm,
    },
})
export default class extends Vue {
    loading = false
    $refs!: any

    get subscriberAreaUser () {
        return SubscriberAreaModule.user
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get userContact () {
        return this.subscriberAreaUser?.credential || ''
    }

    get darkerPrimaryColor () {
        if (!this.primaryColor) return ''
        return pSBC(-0.2, this.primaryColor)
    }

    get layoutCssVars () {
        if (this.primaryColor) {
            return `
                :root {
                    --vs-color-primary-800: ${this.primaryColor};
                    --vs-color-primary-900: ${this.darkerPrimaryColor};
                }
            `
        }
        return ``
    }

    get logoLogin () {
        return SubscriberAreaModule.logoLogin
    }

    get primaryColor () {
        return SubscriberAreaModule.primaryColor
    }

    logout () {
        const userMongoId = this.subscriberAreaUser?.userMongoId || ''
        SubscriberAreaModule.logout()
        this.$router.replace({
            name: 'subscriberAreaLogin',
            params: {
                userId: userMongoId,
            },
        })
    }

    async forgetContact () {
        try {
            await this.$refs.forgetContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await forgetContact()
            this.$root.$vsToast({
                heading: 'Contatto obliato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            const userMongoId = this.subscriberAreaUser?.userMongoId || ''
            SubscriberAreaModule.logout()
            this.$router.replace({
                name: 'subscriberAreaLogin',
                params: {
                    userId: userMongoId,
                },
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dei dati del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
